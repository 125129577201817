@import url("https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@keyframes grow {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes absoluteGrow {
  0% {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slideLeft {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0vw);
  }
}
@keyframes slideExitLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100vw);
  }
}
@keyframes slideRight {
  from {
    transform: translateX(-100vw);
  }
  to {
    transform: translateX(0vw);
  }
}
@keyframes slideExitRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100vw);
  }
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  animation-timing-function: linear;
  transition: all ease-in 250ms;
}

html {
  scroll-behavior: smooth;
  scrollbar-gutter: 4px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -webkit-appearance: textfield;
          appearance: textfield;
  -moz-appearance: textfield;
}

input.pass-code {
  height: 3rem;
  font-size: 3rem;
}

a {
  text-decoration: none;
  color: inherit;
}

.style-reenie {
  font-family: "Reenie Beanie", cursive;
  font-weight: 400;
  font-style: normal;
}

.small-icon {
  height: 0.9rem;
  width: auto;
}

.medium-icon {
  height: 1.2rem;
  width: auto;
}

.token-btn {
  width: 100px;
  overflow: hidden;
  border: 2px solid #666;
}
.token-btn:hover {
  background-color: #121213;
}

.spinner {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner img {
  width: auto;
  height: 3rem;
  margin: auto;
}

.large-icon {
  height: 1.5rem;
  width: auto;
}

.xx-large-icon {
  height: 2.5rem;
  width: auto;
}

.bold-text {
  font-weight: 800 !important;
}

.italic-text {
  font-style: italic !important;
}

.centered-text {
  text-align: center;
}

.large-text {
  font-size: 2rem;
}

.medium-text {
  font-size: 1rem;
}

.x-small-text {
  font-size: 0.6rem;
}

.small-text {
  font-size: 0.8rem;
}

.side-bar {
  padding: 1.2rem;
  background-color: #121213;
  position: relative;
  margin: 1.2rem 0;
  line-height: 1.5em;
  color: #666;
}
.side-bar::before {
  content: "";
  width: 0.3rem;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 0.3rem;
  transform: translate(0, -50%);
  background-color: #1e293b;
}

.nowrap {
  white-space: nowrap;
}

.mixed-img-large-small {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0.6rem;
}
.mixed-img-large-small img.large {
  height: 35px;
  width: -moz-fit-content;
  width: fit-content;
}
.mixed-img-large-small img.small {
  height: 20px;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: calc(100% - 20px);
  left: calc(100% - 20px);
}

.btn {
  padding: 0.6rem 1.2rem;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  border: none;
  cursor: pointer;
  font-weight: 500;
}
.btn:hover {
  opacity: 0.8;
}
.btn.disabled {
  color: #666 !important;
  background-color: #ddd !important;
  cursor: not-allowed;
}
.btn.disabled-with-error {
  color: #ddd !important;
  background-color: red !important;
  cursor: not-allowed;
}
.btn.transparent-btn {
  background-color: transparent !important;
  border: none !important;
}
.btn.return-btn {
  color: #fff;
  font-weight: 800 !important;
}
.btn svg {
  width: 1.8rem;
  height: 1.8rem;
}

.secondary-color-text-hover:hover {
  color: #5ae1e5;
}

.footer-menu {
  display: none;
}

.spaced-btm {
  margin-bottom: 5rem;
}

body {
  color: #fff;
  background-color: #121213;
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  width: 100vw;
  height: 100vh;
  font-size: 16px;
}

header.desktop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #121213;
  z-index: 10;
}
header.desktop nav {
  width: 100%;
  max-width: 1028px;
  padding: 0.6rem 0.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header.desktop nav div > div {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
header.desktop nav .left-column {
  display: flex;
  align-items: center;
  gap: 1.8rem;
}
header.desktop nav .left-column .menu {
  position: relative;
  justify-content: flex-start;
}
header.desktop nav .left-column .menu .menu-container .menu-item {
  padding: 0.6rem 1.8rem;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
}
header.desktop nav .left-column .menu .menu-container .menu-item:hover {
  background-color: #1e293b;
  color: #5ae1e5;
}
header.desktop nav .left-column .menu .menu-drop-content-container {
  width: 60%;
  min-width: 350px;
  padding: 0.6rem;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: rgba(30, 41, 59, 0.868);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  flex-direction: column;
  z-index: 20;
}
header.desktop nav .left-column .menu .menu-drop-content-container a {
  width: 100%;
  animation: grow 350ms;
}
header.desktop nav .left-column .menu .menu-drop-content-container .menu-drop-content-item {
  padding: 0.6rem;
  width: 100%;
}
header.desktop nav .left-column .menu .menu-drop-content-container .menu-drop-content-item:hover {
  background-color: rgba(8, 8, 8, 0.4);
}
header.desktop nav .left-column .menu .menu-drop-content-container .menu-drop-content-item div:first-child {
  display: flex;
  align-items: flex-end;
  font-weight: 500;
  font-size: 0.9rem;
  margin-bottom: 0.1rem;
  color: #5ae1e5;
}
header.desktop nav .left-column .menu .menu-drop-content-container .menu-drop-content-item div:first-child svg {
  height: 1.2rem;
  width: 1.2rem;
}
header.desktop nav .left-column .menu .menu-drop-content-container .menu-drop-content-item div:last-child {
  padding: 0.2rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #666;
}
header.desktop nav .left-column .header-logo {
  height: 100%;
  overflow: hidden;
  height: 100%;
}
header.desktop nav .left-column .header-logo img {
  height: 1.6rem;
  width: auto;
}
header.desktop nav .right-column .actions {
  justify-content: flex-end;
}
header.tab {
  display: none;
}

main,
.container,
.full-width,
.mobile-box {
  width: 100%;
}

.full-height {
  height: 100%;
}

.mobile-box {
  max-width: 350px;
}

.container-1028 {
  max-width: 1028px;
  margin: 0 auto;
}

.box-450 {
  max-width: 450px !important;
}

.box-300 {
  max-width: 300px !important;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.scroll-y {
  overflow-y: auto;
}

.scroll-x {
  overflow-x: auto;
}

.networking {
  background: linear-gradient(to bottom, #1e293b, #121213);
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.networking .partners {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.networking .partners .symbol svg {
  width: 9rem;
  height: 9rem;
  fill: #666;
}
.networking .partners .title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.networking .partners .note {
  width: 100%;
  max-width: 500px;
  color: #666;
  font-size: 1.2rem;
  text-align: center;
}
.networking .partners .partners-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.networking .partners .slide-container {
  box-shadow: 0 1px 350px #ffd436;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 4rem 0;
  background-color: #1e293b;
}
.home-board {
  width: 100%;
  padding: 1.8rem;
  padding-top: 3.5rem;
  display: flex;
}
.home-board .left-board {
  max-width: 492px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home-board .left-board .board-header {
  font-size: 2.9rem;
  font-weight: 900;
  overflow: hidden;
}
.home-board .left-board .board-caption {
  padding: 1.2rem 0;
  font-size: 1.2rem;
  color: #666;
}
.home-board .right-board {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.tx-align-center {
  text-align: center !important;
}

.services {
  padding: 5rem 0;
  background-image: url(../Contents/images/liquidity.jpg);
  background-repeat: no-repeat;
  background-size: fit;
  background-position: center;
}
.services .service-row {
  display: flex;
  align-items: center;
  margin: 6rem 0;
  min-height: 40vh;
  overflow: hidden;
}
.services .service-row .desc-content .desc-title {
  font-size: 2.6rem;
  font-weight: 800;
  line-height: 2.2rem;
  margin: 1.2rem 0;
}
.services .service-row .desc-content .desc-note {
  color: #666;
}
.services .service-row .desc-image {
  flex: 1 0 450px;
  height: 350px;
}
.services .service-row .desc-image img {
  height: 100%;
}

.forums {
  min-height: 60vh;
  background: linear-gradient(to top, #1e293b, #121213);
  display: flex;
  align-items: center;
  justify-content: center;
}
.forums .forums-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: -moz-max-content;
  height: max-content;
  flex-direction: column;
  gap: 2rem;
}
.forums .forums-container .banner {
  font-weight: 800;
  font-size: 2rem;
  color: #5ae1e5;
  text-align: center;
  text-shadow: 0 0 400px #ffd436;
}

.notification-container {
  position: fixed;
  top: 0;
  right: 0.6rem;
  padding: 1rem;
  width: 250px;
  z-index: 9999;
}
.notification-container .notification {
  padding: 0.6rem;
  display: flex;
  align-items: flex-start;
  gap: 0.3rem;
  position: absolute;
  animation-name: slideLeft;
  animation-duration: 350ms;
  cursor: alias;
}
.notification-container .notification.enabled {
  position: absolute;
  right: 0;
  animation: name duration timing-function delay iteration-count direction fill-mode;
  left: 0;
}
.notification-container .notification.disabled {
  position: absolute;
  left: 100%;
  animation: slideRight 350ms;
}
.notification-container .notification.success {
  background-color: #0fa088;
}
.notification-container .notification.error {
  background-color: #f05864;
}
.notification-container .notification.warning {
  background-color: #fac34d;
  color: #000;
}
.notification-container .notification .absolute-right {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  z-index: 1;
}
.notification-container .notification .status-icon svg {
  height: 1.6rem;
  width: 1.6rem;
}
.notification-container .notification .notification-message {
  font-size: 0.8rem;
  overflow-x: auto;
}

.trade-board {
  margin-bottom: 4rem;
}

.dex-stats {
  width: 100%;
  margin-top: 4rem;
}
.dex-stats .stats-slide {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem;
}
.dex-stats .stats-desc {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 600;
  font-style: italic;
  text-shadow: 0 15px 150px #5ae1e5;
  cursor: crosshair;
  color: #666;
  animation: grow 350ms;
}
.dex-stats .stats-desc:hover {
  text-shadow: 0 15px 50px #5ae1e5;
  color: #ddd;
}
.dex-stats .stats-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dex-stats .stats-block .value {
  font-size: 1.8rem;
  font-weight: 700;
  color: #5ae1e5;
}
.dex-stats .stats-block .item {
  color: #666;
  font-weight: 500;
}

.trade-container {
  animation: grow 650ms;
}
.trade-container .controls {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  margin-bottom: 1.2rem;
}
.trade-container .controls .control-item {
  padding: 0.3rem 1.2rem;
  background-color: #0b0e13;
  font-size: 0.8rem;
  font-weight: 700;
  cursor: pointer;
}
.trade-container .controls .control-item.active {
  background-color: #1e293b;
}
.trade-container .content-container {
  width: 100%;
}
.trade-container .content-container .asset-selector {
  width: 100%;
  background-color: #1e293b;
  padding: 1.2rem;
}
.trade-container .content-container .asset-selector > div {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0.6rem 0;
}
.trade-container .content-container .asset-selector > div .value {
  flex: 2;
}
.trade-container .content-container .asset-selector > div .value input {
  width: 100%;
  font-size: 1.6rem;
  font-weight: 600;
  background-color: transparent;
}
.trade-container .content-container .asset-selector > div .token-selector {
  flex: 1;
  justify-content: flex-end;
}
.trade-container .content-container .asset-selector > div .token-selector .token-selector-btn {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  padding: 0.3rem;
  cursor: pointer;
  background-color: #121213;
}
.trade-container .content-container .asset-selector > div .token-selector .token-selector-btn:hover {
  background-color: #121213;
}
.trade-container .content-container .asset-selector > div .token-selector .token-selector-btn img {
  height: 20px;
}
.trade-container .content-container .asset-selector > div .usd-value,
.trade-container .content-container .asset-selector > div .wallet-balance {
  color: #666;
  font-weight: 500;
}
.trade-container .content-container .asset-selector > div .wallet-balance {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.trade-container .content-container .demarcator {
  width: 100%;
  height: 1.2rem;
  position: relative;
}
.trade-container .content-container .demarcator .demarcator-knob {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 2rem;
  width: 2rem;
  background-color: #121213;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.trade-container .content-container .demarcator .demarcator-knob:hover {
  transform: translate(-50%, -50%) rotateZ(180deg);
  transition: all 250ms;
  background-color: rgba(0, 0, 0, 0.468);
  color: #5ae1e5;
}
.trade-container .content-container .trade-data {
  width: 100%;
  margin-top: 1.2rem;
}
.trade-container .content-container .trade-data button {
  padding: 1.2rem;
  font-weight: 700;
  font-size: 0.9rem;
}

.token {
  margin: 0.1rem 0;
  padding: 0.6rem 1.2rem;
  background-color: #121213;
}
.token:hover {
  background-color: #363434;
}
.token:hover .token-balance {
  color: #fff !important;
}
.token .token-balance {
  background-color: transparent;
}

.header-space {
  margin-top: 5vh !important;
}

.hidden-overflow {
  overflow: hidden;
}

.flex-grow-1 {
  flex-grow: 1;
}

.box-max-700,
.box-max-600,
.box-max-500,
.box-max-400,
.box-max-300 {
  width: 100%;
}

.sort-bar {
  position: sticky;
  top: 80px;
  z-index: 1;
}

.container-70 {
  width: 70px;
}

.box-max-700 {
  max-width: 700px;
}

.scroll-y {
  overflow-y: auto;
}

.scroll-x {
  overflow-x: auto;
}

.box-max-500 {
  max-width: 500px;
}

.box-max-400 {
  max-width: 400px;
}

.box-max-300 {
  max-width: 300px;
}

.no-border {
  border: none;
}

.primary-border-1px {
  border: 1px solid #ffd436 !important;
}

.primary-border-2px {
  border: 2px solid #ffd436 !important;
}

.primary-border-bottom-1px {
  border-bottom: 1px solid #ffd436 !important;
}

.secondary-border-1px {
  border: 1px solid #5ae1e5 !important;
}

.secondary-border-2px {
  border: 2px solid #5ae1e5 !important;
}

.small-y-pd-box {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

.small-x-pd-box {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.small-z-pd-box {
  padding: 0.3rem;
}

.medium-y-pd-box {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.medium-x-pd-box {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.medium-z-pd-box {
  padding: 0.6rem;
}

.large-y-pd-box {
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}

.large-x-pd-box {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
}

.large-z-pd-box {
  padding: 0.9rem;
}

.small-y-mg-box {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.small-x-mg-box {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.small-z-mg-box {
  margin: 0.3rem;
}

.medium-y-mg-box {
  margin-left: 0.6rem;
  margin-right: 0.6rem;
}

.medium-x-mg-box {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}

.medium-z-mg-box {
  margin: 0.6rem;
}

.large-y-mg-box {
  margin-left: 0.9rem;
  margin-right: 0.9rem;
}

.large-x-mg-box {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}

.x-large-x-mg-box {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.xx-large-x-mg-box {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.large-z-mg-box {
  margin: 0.9rem;
}

.x-large-z-mg-box {
  margin: 1.2rem;
}

.xx-large-z-mg-box {
  margin: 1.5rem;
}

.content-board {
  background: linear-gradient(to top right, #666 40%, #ffd436);
  border: 0.5px solid #5ae1e5;
}

.home-pool-item {
  position: relative;
  cursor: pointer;
}
.home-pool-item:hover > .link {
  display: flex !important;
}
.home-pool-item .link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.468);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  overflow: hidden !important;
  font-weight: 800;
  font-size: 12px;
  color: #5ae1e5;
}

.input-box {
  position: relative;
}
.input-box label {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: 0;
  gap: 10px;
}

.centered-box-y {
  margin: 0 auto;
}

.centered-box-x {
  margin: auto 0;
}

.centered-box-z {
  margin: auto;
}

.grid-box {
  display: grid;
  grid-auto-rows: auto;
}
.grid-box div {
  height: -moz-fit-content;
  height: fit-content;
}

.grid-box-3x {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-box-2 {
  grid-template-columns: 1fr 1fr;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-box {
  display: flex;
}

.flex-align-justify-center-box {
  align-items: center;
  justify-content: center;
}

.flex-align-center-box {
  align-items: center;
}

.flex-align-baseline-box {
  align-items: baseline;
}

.flex-justify-center-box {
  justify-content: center;
}

.flex-justify-apart {
  justify-content: space-between;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-gap-small {
  gap: 0.3rem;
}

.flex-gap-medium {
  gap: 0.6rem;
}

.flex-gap-large {
  gap: 0.9rem;
}

.border-secondary-color {
  border: 1px solid #5ae1e5;
}

.auto-fill {
  justify-content: flex-end !important;
}

.slippage-form {
  position: relative;
  width: 100%;
  max-width: 150px;
  background-color: #1e293b;
}
.slippage-form input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  padding: 0.9rem;
  color: #fff;
  font-weight: 800;
}
.slippage-form label {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(0, -50%);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5ae1e5;
  padding: 0.9rem;
  color: #000;
}

.search-box {
  position: relative;
}
.search-box input {
  width: 100%;
  padding: 0.6rem;
  padding-left: 2rem;
  color: #666;
  background-color: #121213;
  border: none;
}
.search-box label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 2rem;
}
.search-box label svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: #fff;
}

.content-box {
  width: -moz-fit-content;
  width: fit-content;
}

.selected {
  background-color: #121213;
  position: relative;
  cursor: not-allowed !important;
}
.selected::before {
  content: "Selected";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.568);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 0.8rem;
}

.network-selector {
  flex: 1;
  position: relative;
  cursor: pointer;
}
.network-selector .network-selections {
  position: absolute;
  top: 100%;
  right: 0;
  width: -moz-max-content;
  width: max-content;
  border-top: 2px solid #fff;
  z-index: 1;
}
.network-selector .network-selections .network.selected {
  background-color: #121213;
  position: relative;
  cursor: not-allowed;
}
.network-selector .network-selections .network.selected::before {
  content: "Selected";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.568);
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-input {
  width: 100%;
  max-width: 250px;
  padding: 0.6rem 1.2rem;
  background-color: transparent;
  border: 1px solid #666;
  text-align: center;
  color: #fff;
  font-size: 1.1rem;
}

.hover-fade-background:hover {
  background-color: #1e293b;
}

.hr-line-small-fade {
  width: 100%;
  border: 0.1px solid #1e293b;
}

.square-2x {
  min-width: 2rem;
  height: 2rem;
}

.fade-border-small {
  border: 0.1px solid #1e293b;
}

.cursor-pointer {
  cursor: pointer;
}

.small-svg {
  width: 0.9rem;
  height: 0.9rem;
}

.medium-svg {
  width: 1.2rem;
  height: 1.2rem;
}

.large-svg {
  width: 1.5rem;
  height: 1.5rem;
}

.hover-spin:hover {
  transform: rotate(180deg);
}

.primary-color-text {
  color: #ffd436 !important;
}

.secondary-color-text {
  color: #5ae1e5 !important;
}

.fade-color-text {
  color: #1e293b !important;
}

.grey-color-text {
  color: #666 !important;
}

.dark-color-text {
  color: #000 !important;
}

.light-color-text {
  color: #fff !important;
}

.primary-box-shadow-s {
  box-shadow: 0 10px 20px -15px #ffd436;
}

.light-z-border-2px {
  border: 2px solid #fff;
}

.light-text {
  color: #fff;
}

.green-text {
  color: green;
}

.red-text {
  color: red;
}

.dark-color-background {
  background-color: #121213 !important;
  color: #000;
}

.light-color-background {
  background-color: #c5c9d1 !important;
  color: #fff;
}

.primary-color-background {
  background-color: #ffd436;
}

.secondary-color-background {
  background-color: #5ae1e5;
}

.fade-color-background {
  background-color: #1e293b;
}

.light-fade-color-background {
  background-color: #3d4c63;
}

.grey-color-background {
  background-color: #233044;
}

.transparent-background {
  background-color: transparent;
}

.short-address {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}

.toggle-btn {
  margin: 1rem 0;
}
.toggle-btn .toggle-rail {
  width: 2.5rem;
  height: 1rem;
  background-color: #1e293b;
  cursor: pointer;
  padding: 0 0.2rem;
  border: 2px solid #5ae1e5;
}
.toggle-btn .toggle-rail.active {
  background-color: #5ae1e5;
  justify-content: flex-end;
  border: 2px solid #1e293b;
}
.toggle-btn .toggle-rail.active .toggle-knob {
  border: 2px solid #1e293b;
}
.toggle-btn .toggle-rail .toggle-knob {
  height: 1.5rem;
  width: 1rem;
  background-color: #ffd436;
  border: 2px solid #5ae1e5;
  position: relative;
}
.toggle-btn .toggle-rail .toggle-knob::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  border-top: 2px solid #666;
  border-bottom: 2px solid #666;
}

.danger {
  background-color: rgba(240, 88, 101, 0.603);
  border: 1px solid red;
}

.success {
  background-color: #1bd669;
  border: 1px solid green;
}

.alert-box label {
  display: flex;
  gap: 0.9rem;
  align-items: flex-start;
  font-size: 0.8rem;
  font-weight: 500;
  font-style: italic;
  cursor: pointer;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(30, 41, 59, 0.868);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  overflow: hidden !important;
  z-index: 999;
}
.backdrop .modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1e293b;
  box-shadow: 0 60px 30px -60px #ffd436;
  overflow: hidden !important;
  width: 100%;
  max-width: 450px;
}
.backdrop .modal .modal-header {
  padding: 0.8rem;
  width: 100%;
  background-color: #121213;
}
.backdrop .modal .modal-header svg {
  height: 1.8rem;
  width: 1.8rem;
  cursor: pointer;
}
.backdrop .modal .modal-header svg:hover {
  fill: red;
}
.backdrop .modal .modal-body {
  width: 100%;
  background-image: url(../Contents/images/techface.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-color: #1e293b;
  background-blend-mode: color;
  border: 1px solid #666;
  border-top: none;
  height: calc(70vh - 1.8rem);
  overflow-y: auto;
  padding-bottom: 1rem;
}
.backdrop .modal .modal-body .modal-content {
  padding: 2rem 1.2rem;
  animation: grow 350ms;
}
.backdrop .modal .modal-body .modal-content .options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.2rem;
  flex-wrap: wrap;
  padding: 2rem 0;
}
.backdrop .modal .modal-body .modal-content .options button {
  flex: 1 0 150px;
}
.backdrop .modal .modal-body .modal-content .controls {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.backdrop .modal .modal-body .modal-content .controls > div {
  padding: 0.6rem 1.2rem;
  border: 1px solid #ffd436;
  cursor: pointer;
}
.backdrop .modal .modal-body .modal-content .controls > div:hover {
  background-color: #5ae1e5;
  color: #000 !important;
}
.backdrop .modal .modal-body .modal-content .controls .control-item.active {
  background-color: #ffd436;
  color: #000 !important;
}
.backdrop .modal .modal-body .modal-content .form-content {
  width: 100%;
}
.backdrop .modal .modal-body .modal-content .form-content textarea,
.backdrop .modal .modal-body .modal-content .form-content input {
  width: 100%;
  background-color: transparent;
  border: 1px solid #ffd436;
  color: #fff;
  padding: 0.6rem;
  font-weight: 800;
  animation: grow 350ms;
}
.backdrop .modal .modal-body .modal-content .form-content textarea {
  min-height: 6rem;
}
.backdrop .modal .modal-body .modal-content .logo-container {
  width: 100%;
}
.backdrop .modal .modal-body .modal-content .logo-container img {
  width: 120px;
  height: auto;
}
.backdrop .modal .modal-body .modal-content .seed-container {
  padding: 1.2rem;
  background-color: #121213;
}
.backdrop .modal .modal-body .modal-content .seed-container .seed {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-wrap: wrap;
  gap: 0.6rem;
}
.backdrop .modal .modal-body .modal-content .seed-container .seed span {
  padding: 0.1rem 0.8rem;
  background-color: #1e293b;
}
.backdrop .modal .modal-body .modal-content .seed-container .container-footer {
  margin-top: 1.2rem;
  color: #666;
}
.backdrop .modal .modal-body .modal-content .disclaimer {
  padding: 1.2rem;
}
.backdrop .modal .modal-body .modal-content .disclaimer .row {
  margin: 1.2rem 0;
  color: #868383;
  position: relative;
  padding-left: 1.6rem;
}
.backdrop .modal .modal-body .modal-content .disclaimer .row::before {
  content: "NB";
  width: 1rem;
  height: 1rem;
  background-color: #c5c9d1;
  position: absolute;
  top: 3px;
  left: 0;
  font-size: 0.6rem;
  font-weight: 900;
  color: #837002;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dex-banner {
  position: relative;
}

.centered-bottom-floater {
  padding: 10px;
  font-weight: 800;
  font-size: 12px;
  font-style: italic;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
  background-color: #ffd436;
}

footer {
  width: 100%;
  min-height: 50vh;
  background-color: #1e293b;
  position: relative;
}
footer .footer-container {
  padding: 4rem 0;
}
footer .footer-container .section-1 {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #5ae1e5;
  box-shadow: 0 60px 50px -70px #ffd436;
  margin-bottom: 3rem;
}
footer .footer-container .section-1 .info {
  width: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 15rem;
  justify-content: space-between;
}
footer .footer-container .section-1 .info .site-maps {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  flex-wrap: wrap;
}
footer .footer-container .section-1 .info .site-maps .map {
  flex: 1;
  padding: 10px;
}
footer .footer-container .section-1 .info .site-maps .map .title {
  font-weight: 700;
  color: #5ae1e5;
}
footer .footer-container .section-1 .info .site-maps .map .items {
  padding: 0.3rem;
  font-size: 0.8rem;
  color: #666;
}
footer .footer-container .section-1 .info .site-maps .map .items:hover {
  color: #ffd436;
}
footer .footer-container .section-1 .info .socials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.2rem;
  margin: 1.2rem 0;
}
footer .footer-container .section-1 .info .socials .social-link a {
  position: relative;
}
footer .footer-container .section-1 .info .socials .social-link a .floater {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 0.1rem 0.3rem;
  background-color: #5ae1e5;
  color: #000;
  font-size: 0.6rem;
}
footer .footer-container .section-1 .info .socials .social-link a:hover > .floater {
  display: block;
}
footer .footer-container .section-1 .info .socials .social-link a:hover svg {
  fill: #5ae1e5;
  transform: rotate(-25deg);
}
footer .footer-container .section-1 .footer-logo {
  width: 200px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
footer .footer-container .section-1 .footer-logo .logo-container img {
  width: 50%;
}
footer .footer-container .section-2 .reference {
  color: #666;
  font-size: 0.8rem;
}

.error-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 550px;
}/*# sourceMappingURL=Styles.css.map */