@keyframes grow {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes absoluteGrow {
  0% {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slideLeft {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0vw);
  }
}
@keyframes slideExitLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100vw);
  }
}
@keyframes slideRight {
  from {
    transform: translateX(-100vw);
  }
  to {
    transform: translateX(0vw);
  }
}
@keyframes slideExitRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100vw);
  }
}
@media screen and (max-width: 1080px) {
  header.desktop, header.mobile {
    display: none !important;
  }
  header.tab {
    display: block;
    z-index: 9999;
    position: sticky;
    top: 0;
    left: 0;
    height: 3.5rem;
    background-color: #121213;
  }
  header.tab .mobile-menu {
    height: 100vh;
    width: 95vw;
    background-color: rgba(0, 0, 0, 0.4);
    max-width: 700px;
    background-color: #1e293b;
    overflow: hidden;
  }
  header.tab .mobile-menu .menu-item {
    box-shadow: 0 12px 23px -23px #ffd436;
    cursor: pointer;
  }
  header.tab .mobile-menu .menu-item svg {
    width: 1.2rem !important;
    height: 1.2rem !important;
  }
  header.tab .mobile-menu .menu-item.active {
    background-color: #ffd436;
    color: #000;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  header.tab .mobile-menu .menu-item.active svg {
    transform: rotateZ(-90deg);
  }
  header.tab .mobile-menu .menu-drop-content-container {
    border-left: 3px dotted #ffd436;
    padding-left: 10px;
  }
  header.tab .mobile-menu .menu-drop-content-container .menu-drop-content-item {
    padding: 10px 20px;
  }
  header.tab .mobile-menu .menu-drop-content-container .menu-drop-content-item div:first-child {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 800;
    font-size: 0.9rem;
  }
  header.tab .mobile-menu .menu-drop-content-container .menu-drop-content-item div:first-child svg {
    height: 0.9rem;
    width: 0.9rem;
  }
  header.tab .mobile-menu .menu-drop-content-container .menu-drop-content-item div:last-child {
    font-size: 0.75rem;
    font-weight: 500;
  }
  .section-1 {
    flex-direction: column-reverse;
  }
  .section-1 .info {
    width: 100% !important;
    padding: 10px;
  }
  .section-1 .footer-logo {
    flex: 0;
    width: 250px !important;
    height: 2rem !important;
    padding: 20px;
  }
}
@media screen and (max-width: 1040px) {
  .container-1028 {
    padding: 10px;
  }
}
@media screen and (max-width: 950px) {
  .left-board {
    max-width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50vh;
  }
  .right-board .trade-container {
    display: none !important;
  }
  .stats-desc {
    text-align: center;
  }
}
@media screen and (max-width: 830px) {
  .slide-container {
    overflow-x: auto;
    width: 100vw;
    flex-wrap: nowrap !important;
  }
  .slide-container .slide {
    width: 100%;
    max-width: 300px;
  }
  .service-row {
    flex-direction: column;
  }
  .service-row:nth-child(odd) {
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 800px) {
  .banner-text {
    display: none !important;
  }
  .grid-box-3x {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 650px) {
  .sort-bar {
    flex-direction: column;
  }
  .site-maps {
    flex-direction: column;
  }
  .site-maps .title {
    text-align: left !important;
  }
}
@media screen and (max-width: 550px) {
  .home-pool {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }
  .partners > .title {
    text-align: center !important;
  }
  .grid-box-3x {
    grid-template-columns: 1fr;
  }
  .container-1028 {
    padding: 0;
    margin: 0;
  }
  .dex-stats {
    padding: 0;
    margin: 0;
  }
  .stats-slide {
    width: 100vw !important;
    overflow-x: scroll !important;
  }
  .stats-slide .stats-block .value, .stats-slide .stats-block .item {
    text-align: center;
    width: 50vw !important;
  }
  .main-tokens {
    display: flex;
  }
  .main-tokens .symbol {
    display: none;
  }
}
@media screen and (max-width: 400px) {
  .trade-container {
    padding: 10px;
  }
  .footer-menu {
    display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #1e293b;
    box-shadow: 0px -10px 25px -21px #ffd436;
  }
  .footer-menu .menu-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }
  .footer-menu .menu-item svg {
    height: 30px;
    width: 30px;
  }
  .footer-menu .menu-item.active {
    background-color: #3d4c63;
    color: #5ae1e5 !important;
  }
  .menu-drop-content-item {
    padding: 0.6rem;
    width: 100%;
  }
  .menu-drop-content-item:hover {
    background-color: rgba(8, 8, 8, 0.4);
  }
  .menu-drop-content-item div:first-child {
    display: flex;
    align-items: flex-end;
    background-color: #3d4c63;
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 0.1rem;
    color: #5ae1e5;
  }
  .menu-drop-content-item div:first-child svg {
    height: 3rem !important;
    width: 3rem !important;
    fill: #ffd436;
    stroke: #5ae1e5 !important;
  }
}/*# sourceMappingURL=Media.css.map */