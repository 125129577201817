@import './mixins';
@import './variables';
@import './keyframes';

@media screen and (max-width: 1080px) {
    header.desktop, header.mobile {
        display: none !important
    }
    header.tab {
        display: block;
        z-index: 9999;
        position: sticky;
        top: 0;
        left: 0;
        height: 3.5rem;
        background-color: $dark-background-color;

        .mobile-menu {
            height: 100vh;
            width: 95vw;
            background-color: rgba(0,0,0,0.4);
            max-width: 700px;
            background-color: $fade-color;
            overflow: hidden;

            .menu-item {
                box-shadow: 0 12px 23px -23px $primary-color;
                cursor: pointer;

                svg {
                    width: 1.2rem !important;
                    height: 1.2rem !important;
                }

                &.active {
                    background-color: $primary-color;
                    color: $dark-text;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;

                    svg {
                        transform: rotateZ(-90deg);
                    }
                }
            }

            .menu-drop-content-container {
                border-left: 3px dotted $primary-color;
                padding-left: 10px;

                .menu-drop-content-item {
                    padding: 10px 20px;

                    div:first-child {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        font-weight: 800;
                        font-size: 0.9rem;

                        svg {
                            height: 0.9rem;
                            width: 0.9rem;
                        }
                    }
                    div:last-child {
                        font-size: 0.75rem;
                        font-weight: 500;
                    }
                }

            }
        }
    }
    .section-1 {
        flex-direction: column-reverse;

        .info {
            width: 100% !important;
            padding: 10px;
        }
        .footer-logo {
            flex: 0;
            width: 250px !important;
            height: 2rem !important;
            padding: 20px;
        }
    }
}
@media screen and (max-width: 1040px) {
    .container-1028 {
        padding: 10px;
    }
}
@media screen and (max-width: 950px) {
    .left-board {
        max-width: 100% !important; 
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 50vh;
    }
    .right-board {
        .trade-container {
            display: none !important;
        }
    }
    .stats-desc {
        text-align: center;
    }
}
@media screen and (max-width: 830px) {
    .slide-container {
        overflow-x: auto;
        width: 100vw;
        flex-wrap: nowrap !important;

        .slide {
            width: 100%;
            max-width: 300px;
        }
    }
    .service-row {
        flex-direction: column;

        &:nth-child(odd) {
            flex-direction: column-reverse;
        }
    }
}
@media screen and (max-width: 800px) {
    .banner-text {
        display: none !important;
    }
    .grid-box-3x {
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width: 650px) {
    .sort-bar {
        flex-direction: column;
    }
    .site-maps {
        flex-direction: column;

        .title {
            text-align: left !important;
        }
    }
}
@media screen and (max-width: 550px) {
    .home-pool {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
    }
    .partners > .title {
        text-align: center !important;
    }
    .grid-box-3x {
        grid-template-columns: 1fr;
    }
    .container-1028 {
        padding: 0;
        margin: 0;
    }
    .dex-stats {
        padding: 0;
        margin: 0;
    }
    .stats-slide {
        width: 100vw !important;
        overflow-x: scroll !important;

        .stats-block {
            .value, .item {
                text-align: center;
                width: 50vw !important;
            }
        }
    }
    .main-tokens {
        display: flex;

        .symbol {
            display: none;
        }
    }
}
@media screen and (max-width: 400px) {
    .trade-container {
        padding: 10px;
    }
    .footer-menu {
        display: flex;
        align-items: center;
        position: fixed;
        left: 0;
        bottom: 0;
        background-color: $fade-color;
        box-shadow: 0px -10px 25px -21px $primary-color;

        .menu-item {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem;

            svg {
                height: 30px;
                width: 30px;
            }

            &.active {
                background-color: $light-fade-color;
                color: $secondary-color !important;
            }
        }
    }
    .menu-drop-content-item {
        padding: 0.6rem;
        width: 100%;

        &:hover {
            background-color: rgba(8, 8, 8, 0.4);
        }

        div:first-child {
            display: flex;
            align-items: flex-end;
            background-color: $light-fade-color;
            font-weight: 500;
            font-size: 0.9rem;
            margin-bottom: 0.1rem;
            color: $secondary-color;

            svg {
                height: 3rem !important;
                width: 3rem !important;
                fill: $primary-color;
                stroke: $secondary-color !important;
            }
        }
    }
}