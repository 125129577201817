@import './mixins';
@import './variables';
@import './keyframes';
@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    animation-timing-function: linear;
    transition: all ease-in 250ms;
}

html {
    scroll-behavior: smooth;
    scrollbar-gutter: 4px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}
input.pass-code {
    height: 3rem;
    font-size: 3rem;
}

a {
    text-decoration: none;
    color: inherit;
}

.style-reenie {
    font-family: "Reenie Beanie", cursive;
    font-weight: 400;
    font-style: normal;
}

.small-icon {
    height: 0.9rem;
    width: auto;
}

.medium-icon {
    height: 1.2rem;
    width: auto;
}

.token-btn {
    width: 100px;
    overflow: hidden;
    border: 2px solid #666;

    &:hover {
        background-color: $dark-background-color;
    }
}

.spinner {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: auto;
        height: 3rem;
        margin: auto;
    }
}

.large-icon {
    height: 1.5rem;
    width: auto;
}

.xx-large-icon {
    height: 2.5rem;
    width: auto;
}

.bold-text {
    font-weight: 800 !important;
}

.italic-text {
    font-style: italic !important;
}

.centered-text {
    text-align: center;
}

.large-text {
    font-size: 2rem;
}

.medium-text {
    font-size: 1rem;
}

.x-small-text {
    font-size: 0.6rem;
}

.small-text {
    font-size: 0.8rem;
}

.side-bar {
    padding: 1.2rem;
    background-color: $dark-background-color;
    position: relative;
    margin: 1.2rem 0;
    line-height: 1.5em;
    color: #666;

    &::before {
        content: "";
        width: 0.3rem;
        height: 80%;
        position: absolute;
        top: 50%;
        left: 0.3rem;
        transform: translate(0, -50%);
        background-color: $fade-color;
    }
}

.nowrap {
    white-space: nowrap;
}

.mixed-img-large-small {
    position: relative;
    width: fit-content;
    margin: 0.6rem;

    img {
        &.large {
            height: 35px;
            width: fit-content;
        }
        &.small {
            height: 20px;
            width: fit-content;
            position: absolute;
            top: calc(100% - 20px);
            left: calc(100% - 20px);
        }
    }
}

.btn {
    padding: 0.6rem 1.2rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    border: none;
    cursor: pointer;
    font-weight: 500;

    &:hover {
        opacity: 0.8;
    }

    &.disabled {
        color: #666 !important;
        background-color: #ddd !important;
        cursor: not-allowed;
    }

    &.disabled-with-error {
        color: #ddd !important;
        background-color: red !important;
        cursor: not-allowed;
    }

    &.transparent-btn {
        background-color: transparent !important;
        border: none !important;
    }

    &.return-btn {
        color: $light-text;
        font-weight: 800 !important;
    }    

    svg {
        width: 1.8rem;
        height: 1.8rem;
    }
}

.secondary-color-text-hover:hover {
    color: $secondary-color;
}

.footer-menu {
    display: none;
}

.spaced-btm {
    margin-bottom: 5rem;
}

body {
    color: $light-text;
    background-color: $dark-background-color;
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    width: 100vw;
    height: 100vh;
    font-size: 16px;
}

header {
    &.desktop {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: sticky;
        top: 0;
        left: 0;
        background-color: $dark-background-color;
        z-index: 10;
    
        nav {
            width: 100%;
            max-width: 1028px;
            padding: 0.6rem 0.1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
    
    
            & div>div {
                display: flex;
                align-items: center;
                gap: 0.3rem;
            }
    
            .left-column {
                display: flex;
                align-items: center;
                gap: 1.8rem;
    
                .menu {
                    position: relative;
                    justify-content: flex-start;
    
                    .menu-container {
    
                        .menu-item {
                            padding: 0.6rem 1.8rem;
                            font-size: 0.8rem;
                            font-weight: 500;
                            cursor: pointer;
    
                            &:hover {
                                background-color: $fade-color;
                                color: $secondary-color;
                            }
                        }
    
                    }
    
                    .menu-drop-content-container {
                        width: 60%;
                        min-width: 350px;
                        padding: 0.6rem;
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translate(-50%, 0);
                        background-color: rgba(30, 41, 59, 0.868);
                        backdrop-filter: blur(5px);
                        flex-direction: column;
                        z-index: 20;
    
                        a {
                            width: 100%;
                            animation: grow 350ms;
                        }
    
                        .menu-drop-content-item {
                            padding: 0.6rem;
                            width: 100%;
    
                            &:hover {
                                background-color: rgba(8, 8, 8, 0.4);
                            }
    
                            div:first-child {
                                display: flex;
                                align-items: flex-end;
                                font-weight: 500;
                                font-size: 0.9rem;
                                margin-bottom: 0.1rem;
                                color: $secondary-color;
    
                                svg {
                                    height: 1.2rem;
                                    width: 1.2rem;
                                }
                            }
    
                            div:last-child {
                                padding: 0.2rem;
                                font-size: 0.9rem;
                                font-weight: 400;
                                color: #666;
                            }
                        }
                    }
                }
    
                .header-logo {
                    height: 100%;
                    overflow: hidden;
                    height: 100%;
                    // width: 80px;
    
                    img {
                        height: 1.6rem;
                        width: auto;
                    }
                }
            }
    
            .right-column {
                .actions {
                    justify-content: flex-end;
                }
            }
        }
    }
    &.tab {
        display: none;
    }
}

main,
.container,
.full-width,
.mobile-box {
    width: 100%;
}

.full-height {
    height: 100%;
}

.mobile-box {
    max-width: 350px;
}

.container-1028 {
    max-width: 1028px;
    margin: 0 auto;
}

.box-450 {
    max-width: 450px !important;
}

.box-300 {
    max-width: 300px !important;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.scroll-y {
    overflow-y: auto;
}

.scroll-x {
    overflow-x: auto;
}

.networking {
    background: linear-gradient(to bottom, $fade-color, $dark-background-color);
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .partners {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .symbol {
            svg {
                width: 9rem;
                height: 9rem;
                fill: #666;
            }
        }

        .title {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 2rem;
        }

        .note {
            width: 100%;
            max-width: 500px;
            color: #666;
            font-size: 1.2rem;
            text-align: center;
        }

        .partners-slide {
            display: flex;
            align-items: center;
            justify-content: center;

        }

        .slide-container {
            box-shadow: 0 1px 350px $primary-color;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 1rem;
            margin: 4rem 0;
            background-color: $fade-color;

            .slide {

                .slide-img {}
            }
        }
    }
}


.home-board {
    width: 100%;
    padding: 1.8rem;
    padding-top: 3.5rem;
    display: flex;

    .left-board {
        max-width: 492px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .board-header {
            font-size: 2.9rem;
            font-weight: 900;
            overflow: hidden;
        }

        .board-caption {
            padding: 1.2rem 0;
            font-size: 1.2rem;
            color: #666;

        }
    }

    .right-board {
        flex: 1;
        display: flex;
        justify-content: flex-end;

    }
}

.tx-align-center {
    text-align: center !important;
}

.services {
    // min-height: 100vh;
    padding: 5rem 0;
    background-image: url(../Contents/images/liquidity.jpg);
    background-repeat: no-repeat;
    background-size: fit;
    background-position: center;

    .service-row {
        display: flex;
        align-items: center;
        margin: 6rem 0;
        min-height: 40vh;
        overflow: hidden;

        .desc-content {
            .desc-title {
                font-size: 2.6rem;
                font-weight: 800;
                line-height: 2.2rem;
                margin: 1.2rem 0;
            }

            .desc-note {
                color: #666;
            }
        }

        .desc-image {
            flex: 1 0 450px;
            height: 350px;

            img {
                height: 100%;
            }
        }
    }
}

.forums {
    min-height: 60vh;
    background: linear-gradient(to top, $fade-color, $dark-background-color);
    display: flex;
    align-items: center;
    justify-content: center;

    .forums-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: max-content;
        flex-direction: column;
        gap: 2rem;

        .banner {
            // padding: 2rem;
            font-weight: 800;
            font-size: 2rem;
            color: $secondary-color;
            text-align: center;
            text-shadow: 0 0 400px $primary-color;
        }
    }
}

.notification-container {
    position: fixed;
    top: 0;
    right: 0.6rem;
    padding: 1rem;
    width: 250px;
    z-index: 9999;

    .notification {
        // width: calc(100% - 0.6rem);
        padding: 0.6rem;
        display: flex;
        align-items: flex-start;
        gap: 0.3rem;
        position: absolute;
        animation-name: slideLeft;
        animation-duration: 350ms;
        cursor: alias;

        &.enabled {
            // slide in animation
            position: absolute;
            right: 0;
            animation: name duration timing-function delay iteration-count direction fill-mode;
            left: 0;
        }

        &.disabled {
            // slide out animation
            position: absolute;
            left: 100%;
            animation: slideRight 350ms;
        }

        &.success {
            background-color: #0fa088;
        }

        &.error {
            background-color: #f05864;
        }

        &.warning {
            background-color: #fac34d;
            color: #000;
        }

        .absolute-right {
            position: absolute;
            top: 0.3rem;
            right: 0.3rem;
            z-index: 1;
        }

        .status-icon {
            svg {
                height: 1.6rem;
                width: 1.6rem;
            }
        }

        .notification-message {
            font-size: 0.8rem;
            overflow-x: auto;
        }
    }
}

.trade-board {
    margin-bottom: 4rem;
}

.dex-stats {
    width: 100%;
    margin-top: 4rem;

    .stats-slide {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.2rem;
    }

    .stats-desc {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        font-weight: 600;
        font-style: italic;
        text-shadow: 0 15px 150px $secondary-color;
        cursor: crosshair;
        color: #666;
        animation: grow 350ms;

        &:hover {
            text-shadow: 0 15px 50px $secondary-color;
            color: #ddd;
        }
    }

    .stats-block {
        display: flex;
        flex-direction: column;
        align-items: center;

        .value {
            font-size: 1.8rem;
            font-weight: 700;
            color: $secondary-color;
        }

        .item {
            color: #666;
            font-weight: 500;
        }
    }
}

.trade-container {
    animation: grow 650ms;

    .controls {
        display: flex;
        align-items: center;
        gap: 0.6rem;
        margin-bottom: 1.2rem;

        .control-item {
            padding: 0.3rem 1.2rem;
            background-color: #0b0e13;
            font-size: 0.8rem;
            font-weight: 700;
            cursor: pointer;
        }

        .control-item.active {
            background-color: $fade-color;
        }
    }

    .content-container {
        width: 100%;

        .asset-selector {
            width: 100%;
            background-color: $fade-color;
            padding: 1.2rem;

            &>div {
                width: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                margin: 0.6rem 0;

                .value {
                    flex: 2;
                    // background-color: red;
                    input {
                        width: 100%;
                        font-size: 1.6rem;
                        font-weight: 600;
                        background-color: transparent;
                    }
                }

                .token-selector {
                    flex: 1;
                    justify-content: flex-end;
                    .token-selector-btn {
                        display: flex;
                        align-items: center;
                        gap: 0.3rem;
                        padding: 0.3rem;
                        cursor: pointer;
                        background-color: $dark-background-color;

                        &:hover {
                            background-color: $dark-background-color;
                        }

                        img {
                            height: 20px;
                        }
                    }
                }

                .usd-value,
                .wallet-balance {
                    color: #666;
                    font-weight: 500;
                }

                .wallet-balance {
                    display: flex;
                    align-items: center;
                    gap: 0.3rem;
                }
            }
        }

        .demarcator {
            width: 100%;
            height: 1.2rem;
            position: relative;

            .demarcator-knob {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 2rem;
                width: 2rem;
                background-color: $dark-background-color;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    transform: translate(-50%, -50%) rotateZ(180deg);
                    transition: all 250ms;
                    background-color: rgba(0, 0, 0, 0.468);
                    color: $secondary-color;
                }
            }
        }

        .trade-data {
            width: 100%;
            margin-top: 1.2rem;

            button {
                padding: 1.2rem;
                font-weight: 700;
                font-size: 0.9rem;
            }
        }
    }
}

.token {
    margin: 0.1rem 0;
    padding: 0.6rem 1.2rem;
    background-color: $dark-background-color;

    &:hover {
        background-color: #363434;

        .token-balance {
            color: $light-text !important;
        }
    }

    .token-balance {
        background-color: transparent;
    }
}

.header-space {
    margin-top: 5vh !important;
}

.hidden-overflow {
    overflow: hidden;
}

.flex-grow-1 {
    flex-grow: 1;
}

.box-max-700,
.box-max-600,
.box-max-500,
.box-max-400,
.box-max-300 {
    width: 100%;
}

.sort-bar {
    position: sticky;
    top: 80px;
    z-index: 1;
}

.container-70 {
    width: 70px;
}

.box-max-700 {
    max-width: 700px;
}
.scroll-y {
    overflow-y: auto;
}

.scroll-x {
    overflow-x: auto;
}

.box-max-500 {
    max-width: 500px;
}

.box-max-400 {
    max-width: 400px;
}

.box-max-300 {
    max-width: 300px;
}

.no-border {
    border: none;
}
.primary-border-1px {
    border: 1px solid $primary-color !important;
}
.primary-border-2px {
    border: 2px solid $primary-color !important;
}
.primary-border-bottom-1px {
    border-bottom: 1px solid $primary-color !important;
}
.secondary-border-1px {
    border: 1px solid $secondary-color !important;
}
.secondary-border-2px {
    border: 2px solid $secondary-color !important;
}

.small-y-pd-box {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}

.small-x-pd-box {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}

.small-z-pd-box {
    padding: 0.3rem;
}

.medium-y-pd-box {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
}

.medium-x-pd-box {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
}

.medium-z-pd-box {
    padding: 0.6rem;
}

.large-y-pd-box {
    padding-left: 0.9rem;
    padding-right: 0.9rem;
}

.large-x-pd-box {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
}

.large-z-pd-box {
    padding: 0.9rem;
}

.small-y-mg-box {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

.small-x-mg-box {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
}

.small-z-mg-box {
    margin: 0.3rem;
}

.medium-y-mg-box {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
}

.medium-x-mg-box {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
}

.medium-z-mg-box {
    margin: 0.6rem;
}

.large-y-mg-box {
    margin-left: 0.9rem;
    margin-right: 0.9rem;
}

.large-x-mg-box {
    margin-top: 0.9rem;
    margin-bottom: 0.9rem;
}

.x-large-x-mg-box {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
}

.xx-large-x-mg-box {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.large-z-mg-box {
    margin: 0.9rem;
}

.x-large-z-mg-box {
    margin: 1.2rem;
}

.xx-large-z-mg-box {
    margin: 1.5rem;
}

.content-board {
    background: linear-gradient(to top right, #666 40%, $primary-color);
    border: 0.5px solid $secondary-color;
}

.home-pool-item {
    position: relative;
    cursor: pointer;

    &:hover > .link {
        display: flex !important;
    }

    .link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background-color: rgba(0, 0, 0, 0.468);
        backdrop-filter: blur(5px);
        overflow: hidden !important;
        font-weight: 800;
        font-size: 12px;
        color: $secondary-color;
    }
}

.input-box {
    position: relative;

    label {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: 0;
        gap: 10px;
    }
}

.centered-box-y {
    margin: 0 auto;
}

.centered-box-x {
    margin: auto 0;
}

.centered-box-z {
    margin: auto;
}

.grid-box {
    display: grid;
    grid-auto-rows: auto;

    div {
        height: fit-content;
    }
}

.grid-box-3x {
    grid-template-columns: 1fr 1fr 1fr;
}

.grid-box-2 {
    grid-template-columns: 1fr 1fr;
}

.flex-direction-column {
    flex-direction: column;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-box {
    display: flex;
}

.flex-align-justify-center-box {
    align-items: center;
    justify-content: center;
}

.flex-align-center-box {
    align-items: center;
}

.flex-align-baseline-box {
    align-items: baseline;
}

.flex-justify-center-box {
    justify-content: center;
}

.flex-justify-apart {
    justify-content: space-between;
}

.flex-justify-end {
    justify-content: flex-end;
}

.flex-gap-small {
    gap: 0.3rem;
}

.flex-gap-medium {
    gap: 0.6rem;
}

.flex-gap-large {
    gap: 0.9rem;
}

.border-secondary-color {
    border: 1px solid $secondary-color;
}

.auto-fill {
    justify-content: flex-end !important;
}

.slippage-form {
    position: relative;
    width: 100%;
    max-width: 150px;
    background-color: $fade-color;

    input {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        padding: 0.9rem;
        color: $light-text;
        font-weight: 800;
    }
    label {
        position: absolute;
        top: 50%;
        right: 0%;
        transform: translate(0, -50%);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $secondary-color;
        padding: 0.9rem;
        color: $dark-text;
    }
}

.search-box {
    position: relative;

    input {
        width: 100%;
        padding: 0.6rem;
        padding-left: 2rem;
        color: #666;
        background-color: $dark-background-color;
        border: none;
    }
    label {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 2rem;

        svg {
            width: 1.2rem;
            height: 1.2rem;
            fill: $light-text;
        }
    }
} 

.content-box {
    width: fit-content;
}

.selected {
    background-color: $dark-background-color;
    position: relative;
    cursor: not-allowed !important;

    &::before {
        content: "Selected";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.568);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 0.8rem;
    }
}

.network-selector {
    flex: 1;
    position: relative;
    cursor: pointer;

    .network-selections {
        position: absolute;
        top: 100%;
        right: 0;
        width: max-content;
        border-top: 2px solid $light-text;
        z-index: 1;

        .network.selected {
            background-color: $dark-background-color;
            position: relative;
            cursor: not-allowed;

            &::before {
                content: "Selected";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.568);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.auth-input {
    width: 100%;
    max-width: 250px;
    padding: 0.6rem 1.2rem;
    background-color: transparent;
    border: 1px solid #666;
    text-align: center;
    color: $light-text;
    font-size: 1.1rem;
}

.hover-fade-background:hover {
    background-color: $fade-color;
}

.hr-line-small-fade {
    width: 100%;
    border: 0.1px solid $fade-color;
}

.square-2x {
    min-width: 2rem;
    height: 2rem;
}

.fade-border-small {
    border: 0.1px solid $fade-color;
}

.cursor-pointer {
    cursor: pointer;
}

.small-svg {
    width: 0.9rem;
    height: 0.9rem;
}

.medium-svg {
    width: 1.2rem;
    height: 1.2rem;
}

.large-svg {
    width: 1.5rem;
    height: 1.5rem;
}

.hover-spin:hover {
    transform: rotate(180deg);
}

.primary-color-text {
    color: $primary-color !important;
}

.secondary-color-text {
    color: $secondary-color !important;
}

.fade-color-text {
    color: $fade-color !important;
}

.grey-color-text {
    color: #666 !important;
}

.dark-color-text {
    color: $dark-text !important;
}

.light-color-text {
    color: $light-text !important;
}

.primary-box-shadow-s {
    box-shadow: 0 10px 20px -15px $primary-color;
}

.light-z-border-2px {
    border: 2px solid $light-text;
}

.light-text {
    color: $light-text;
}

.green-text {
    color: green;
}

.red-text {
    color: red;
}

.dark-color-background {
    background-color: $dark-background-color !important;
    color: $dark-text;
}

.light-color-background {
    background-color: $light-background-color !important;
    color: $light-text;
}

.primary-color-background {
    background-color: $primary-color;
}

.secondary-color-background {
    background-color: $secondary-color;
}

.fade-color-background {
    background-color: $fade-color;
}

.light-fade-color-background {
    background-color: $light-fade-color;
}

.grey-color-background {
    background-color: #233044;
}

.transparent-background {
    background-color: transparent;
}

.short-address {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
}

.toggle-btn {
    margin: 1rem 0;

    .toggle-rail {
        width: 2.5rem;
        height: 1rem;
        background-color: $fade-color;
        cursor: pointer;
        padding: 0 0.2rem;
        border: 2px solid $secondary-color;

        &.active {
            background-color: $secondary-color;
            justify-content: flex-end;
            border: 2px solid $fade-color;
            
            .toggle-knob {
                border: 2px solid $fade-color;
            }
        }

        .toggle-knob {
            height: 1.5rem;
            width: 1rem;
            background-color: $primary-color;
            border: 2px solid $secondary-color;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 80%;
                height: 80%;
                border-top: 2px solid #666;
                border-bottom: 2px solid #666;
            }
        }
    }
}

.danger {
    background-color: rgba(240, 88, 101, 0.603);
    border: 1px solid red;
}
.success {
    background-color: #1bd669;
    border: 1px solid green;
}
.alert-box {
    label {
        display: flex;
        gap: 0.9rem;
        align-items: flex-start;
        font-size: 0.8rem;
        font-weight: 500;
        font-style: italic;
        cursor: pointer;
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(30, 41, 59, 0.868);
    backdrop-filter: blur(5px);
    overflow: hidden !important;
    z-index: 999;

    .modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $fade-color;
        box-shadow: 0 60px 30px -60px $primary-color;
        overflow: hidden !important;
        width: 100%;
        max-width: 450px;

        .modal-header {
            padding: 0.8rem;
            width: 100%;
            background-color: $dark-background-color;

            svg {
                height: 1.8rem;
                width: 1.8rem;
                cursor: pointer;

                &:hover {
                    fill: red;
                }
            }
        }

        .modal-body {
            width: 100%;
            background-image: url(../Contents/images/techface.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
            background-color: $fade-color;
            background-blend-mode: color;
            border: 1px solid #666;
            border-top: none;
            height: calc(70vh - 1.8rem);
            overflow-y: auto;
            padding-bottom: 1rem;

            .modal-content {
                padding: 2rem 1.2rem;
                animation: grow 350ms;

                .options {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 1.2rem;
                    flex-wrap: wrap;
                    padding: 2rem 0;

                    button {
                        flex: 1 0 150px;
                    }
                }

                .controls {
                    display: flex;
                    align-items: center;
                    gap: 0.3rem;

                    &>div {
                        padding: 0.6rem 1.2rem;
                        border: 1px solid $primary-color;
                        cursor: pointer;

                        &:hover {
                            background-color: $secondary-color;
                            color: $dark-text !important;
                        }
                    }
                    .control-item.active {
                        background-color: $primary-color;
                        color: $dark-text !important;
                    }
                }

                .form-content {
                    width: 100%;

                    textarea,
                    input {
                        width: 100%;
                        background-color: transparent;
                        border: 1px solid $primary-color;
                        color: $light-text;
                        padding: 0.6rem;
                        font-weight: 800;
                        animation: grow 350ms;
                    }

                    textarea {
                        min-height: 6rem;
                    }
                }

                .logo-container {
                    width: 100%;

                    img {
                        width: 120px;
                        height: auto;
                    }
                }

                .seed-container {
                    padding: 1.2rem;
                    background-color: $dark-background-color;

                    .seed {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        flex-wrap: wrap;
                        gap: 0.6rem;

                        span {
                            padding: 0.1rem 0.8rem;
                            background-color: $fade-color;
                        }
                    }

                    .container-footer {
                        margin-top: 1.2rem;
                        color: #666;
                    }
                }

                .disclaimer {
                    padding: 1.2rem;

                    .row {
                        margin: 1.2rem 0;
                        color: #868383;
                        position: relative;
                        padding-left: 1.6rem;

                        &::before {
                            content: "NB";
                            width: 1rem;
                            height: 1rem;
                            background-color: $light-background-color;
                            position: absolute;
                            top: 3px;
                            left: 0;
                            font-size: 0.6rem;
                            font-weight: 900;
                            color: #837002;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}

.dex-banner {
    position: relative;
}

.centered-bottom-floater {
    padding: 10px;
    font-weight: 800;
    font-size: 12px;
    font-style: italic;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
    background-color: $primary-color;
}

// footer
footer {
    width: 100%;
    min-height: 50vh;
    background-color: $fade-color;
    position: relative;

    .footer-container {
        padding: 4rem 0;

        .section-1 {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid $secondary-color;
            box-shadow: 0 60px 50px -70px $primary-color;
            margin-bottom: 3rem;

            .info {
                width: calc(100% - 200px);
                display: flex;
                flex-direction: column;
                gap: 1rem;
                min-height: 15rem;
                justify-content: space-between;

                .site-maps {
                    display: flex;
                    align-items: flex-start;
                    gap: 2rem;
                    flex-wrap: wrap;

                    .map {
                        flex: 1;
                        padding: 10px;

                        .title {
                            font-weight: 700;
                            color: $secondary-color;
                        }

                        .items {
                            padding: 0.3rem;
                            font-size: 0.8rem;
                            color: #666;

                            &:hover {
                                color: $primary-color;
                            }
                        }
                    }
                }

                .socials {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 1.2rem;
                    margin: 1.2rem 0;

                    .social-link {
                        a {
                            position: relative;

                            .floater {
                                display: none;
                                position: absolute;
                                top: 100%;
                                left: 50%;
                                transform: translate(-50%, 0);
                                padding: 0.1rem 0.3rem;
                                background-color: $secondary-color;
                                color: $dark-text;
                                font-size: 0.6rem;
                            }

                            &:hover>.floater {
                                display: block;
                            }

                            &:hover {
                                svg {
                                    fill: $secondary-color;
                                    transform: rotate(-25deg);
                                }
                            }
                        }
                    }
                }
            }

            .footer-logo {
                width: 200px;
                overflow: hidden;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                .logo-container {
                    img {
                        width: 50%;
                    }
                }
            }
        }

        .section-2 {
            .reference {
                color: #666;
                font-size: 0.8rem;
            }
        }
    }
}

.error-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 550px;
}