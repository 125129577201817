@keyframes grow {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes absoluteGrow {
  0% {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slideLeft {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0vw);
  }
}
@keyframes slideExitLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100vw);
  }
}
@keyframes slideRight {
  from {
    transform: translateX(-100vw);
  }
  to {
    transform: translateX(0vw);
  }
}
@keyframes slideExitRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100vw);
  }
}
.animate-grow {
  animation: grow 350ms;
}

.animate-slide-left {
  position: relative;
  animation: slideLeft 350ms forwards;
}

.animate-slide-right {
  position: relative;
  animation: slideRight 350ms forwards;
}/*# sourceMappingURL=Animations.css.map */